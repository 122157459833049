@import '../../styles/variables.scss';

.experience-section {
	align-items: flex-start;
	background-color: var(--background-secondary);
	display: flex;
	justify-content: center;
	min-height: 100vh;
	padding: 2rem;
	transition: background-color 0.4s ease, color 0.4s ease;

	.experience-container {
		margin: 0 auto;
		max-width: 1200px;
		width: 100%;

		.section-title {
			color: var(--primary-color);
			font-size: 2rem;
			margin-bottom: 1rem;
			text-align: center;
		}

		.experience-timeline {
			margin: 0 auto;
			max-width: 750px;
			position: relative;

			&:before {
				background: var(--secondary-color);
				bottom: 0;
				content: '';
				left: 50px;
				margin-left: -1px;
				position: absolute;
				top: 0;
				width: 2px;
			}

			.experience-timeline-item {
				align-items: center;
				display: flex;
				margin: 4rem 0;
				position: relative;
				padding: 1rem;
				background: rgba(var(--primary-color-rgb, 0, 123, 255), 0.05);
				border-radius: 8px;

				.experience-year {
					background-color: var(--primary-color);
					border-radius: 10px;
					color: var(--background-secondary);
					font-size: 0.875rem;
					font-weight: bold;
					padding: 0.25rem 0.5rem;
					position: absolute;
					text-align: center;
					top: -10px;
					left: 25px;
					min-width: 50px;
					transform: translateX(-100%);
					white-space: nowrap;
					z-index: 1;
				}

				.experience-circle {
					background: var(--secondary-color);
					border: 2px solid var(--primary-color);
					border-radius: 50%;
					height: 15px;
					left: 41px;
					margin-top: -7.5px;
					position: absolute;
					top: 0;
					width: 15px;
					z-index: 2;
				}

				.experience-content {
					margin-left: 70px;
					padding-left: 20px;
					position: relative;
					top: -10px;

					.experience-title {
						color: var(--primary-color);
						font-size: 1.25rem;
						font-weight: bold;
					}

					.experience-role {
						color: var(--primary-text-color);
						font-weight: bold;
						margin-top: -15px;
					}

					.experience-description {
						color: var(--primary-text-color);
						line-height: 1.5;
						margin-top: 0.5rem;
					}
				}

				@media (max-width: $breakpoint-tablet) {
					.experience-content {
						margin-left: 60px;

						.experience-title {
							font-size: 1.1rem;
						}

						.experience-role {
							font-size: 14px;
						}

						.experience-description {
							font-size: 14px;
						}
					}
				}

				@media (max-width: $breakpoint-desktop) {
					.experience-year {
						white-space: normal;
						width: 50px;
						left: 5px;
						transform: translateX(-50%);
					}
				}

				@media (max-width: $breakpoint-mobile) {
					.experience-year {
						font-size: 12px;
						left: 5px;
						transform: translateX(-50%);
						width: 40px;
					}
				}
			}
		}
	}
}
